<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            5. Первая помощь при травматическом шоке
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
            >
            <strong>Травматический шок </strong> - тяжелое, угрожающее жизни патологическое состояние, возникающее при тяжелых травмах (переломы костей таза, большая потеря крови, черепно-мозговая травма, повреждение внутренних органов).
          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-card-title class="mb-10">
            Симптомы:
          </v-card-title>
          <div  class="ptm-img-rel d-flex justify-center align-center ma-5 mt-16">
            <v-img
              lazy-src="img/material/man-2-num.svg"
              width="30%"
              contain
              src="img/material/man-2-num.svg"
              class="mr-10 ptm-img"
            ></v-img>
            <v-tooltip left color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-1 title pa-5 red">1</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Наличие тяжелой травмы и сильное кровотечение</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-2 title pa-5 red">2</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Возбуждение, сменяющийся апатией</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-3 title pa-5 red">3</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Учащенное дыхание и сердцебиение</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-4 title pa-5 red">4</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Бледная холодная влажная кожа</span>
            </v-tooltip>
          </div>
            <v-sheet flat class="d-flex justify-center mx-auto pb-0 mt-2">
              <v-card class="d-flex justify-center" flat>
                <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
                <v-card-title class="font-weight-light">
                  Наводите на кнопки для изучения информации
                </v-card-title>
              </v-card>
            </v-sheet>
        </v-col>

        <v-col cols="6">
          <v-card-title>
            Действия:
          </v-card-title>
          <!-- <v-card-text class="title font-weight-light mb-5">
            Вход в рот или нос делайте через смоченную марлевую салфетку (носовой платок и др.), а при пассивном выходе пострадавшего отклоняйте свою голову в сторону, чтобы выдыхаемый газ не попал Вам в легкие.
          </v-card-text> -->
          <v-card height="200" flat>
            <v-tabs
            v-model="tab"
            grow
            color="red"
            slider-color="white"
            >
              <v-tab class="pa-5">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-1-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-2-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-3-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-4-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-5-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
            </v-tabs>

            <v-tabs-items class="mb-10" v-model="tab">
              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10">
                  Остановить кровотечение
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Уложить пострадавшего на спину, при рвоте повернуть голову набок
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Проверить дыхание и сердцебиение, при необходимости начать реанимационные мероприятия
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Иммобилизовать травмированные конечности
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Защитить от переохлаждения
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  При отсутствии сознания придайте устойчивое боковое положение
                </v-card-text>
                <v-card-text class="title font-weight-light">
                  При рвоте поверните голову в сторону, чтобы не дать задохнуться. Следите за отхождением рвотной массы
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <v-card class="pa-5 mb-5">
            <v-card-title class="red--text pt-0">
              ЗАПРЕЩЕНО:
            </v-card-title>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              переносить пострадавшего без необходимости;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              снимать прилипшую после ожога одежду;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              давать пить при жалобах на боль в животе;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              оставлять пострадавшего без наблюдения.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-img-rel {
  position: relative;
}
.ptm-img-man-2-item-1 {
  position: absolute;
  top: -4%;
  right: 55%;
}
.ptm-img-man-2-item-2 {
  position: absolute;
  top: 7%;
  right: 5%;
}
.ptm-img-man-2-item-3 {
  position: absolute;
  top: 44%;
  right: 5%;
}
.ptm-img-man-2-item-4 {
  position: absolute;
  top: 80%;
  right: 5%;
}
</style>
